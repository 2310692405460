<template>
  <v-app-bar app height="71">
    <v-avatar
      color="blue"
      tile
      class="user-avatar"
      @click="$router.push('/dashboard/timesheet')"
      style="cursor: pointer"
    >
      <v-img :src="require('../../assets/logo-small.svg')"></v-img>
    </v-avatar>
    <v-btn class="ml-2" min-width="0" text>
      <span id="my-account" @click="$router.push('/dashboard/timesheet')"> Piximind</span>
    </v-btn>
    <ul v-for="path in paths" :key="path.name" class="nav-menu">
      <router-link :to="path.url">
        <li class="nav-menu-item">{{ path.name }}</li>
      </router-link>
    </ul>
    <v-spacer></v-spacer>
    <span id="username" v-if="$store.getters.user">{{ $store.getters.user.name }}</span>
    <v-avatar
      v-if="$store.getters.user"
      class="agent-avatar mr-6"
      color="primary"
      size="40"
      @click="$router.push('/dashboard/profile/photo')"
    >
      <v-img :src="userImage" :alt="$store.state.user.name">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-3"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-avatar>
    <v-app-bar-nav-icon @click="ToggleDrawer" class="mr-4" width="32px" height="26px">
      <v-img
        :src="require('@/assets/humberger.svg')"
        alt="humberger"
        width="32px"
        height="26px"
      />
    </v-app-bar-nav-icon>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      imageHost: "",
      user: [],
      drawer: null,
      rolesItems: [
        {
          name: "Roles",
          url: "/dashboard/roles"
        },
        {
          name: "Opérations",
          url: "/dashboard/operations"
        }
      ],
      rapportItems: [
        {
          name: "Rapports centralisés",
          url: "/dashboard/rapport/list"
        },
        {
          name: "Ajout nouveau type de rapport",
          url: "/dashboard/rapport/types"
        }
      ],
      dossierItems: [
        {
          name: "Dossiers",
          url: "/dashboard/dossiers"
        },
        {
          name: "Clients",
          url: "/dashboard/clients"
        }
      ],
      profileItems: [
        {
          name: "Photo",
          url: "/dashboard/profile/photo"
        },
        {
          name: "Mot de passe",
          url: "/dashboard/profile/password"
        }
      ],
      timesheetItems: [
        {
          name: "Centralisés",
          url: "/dashboard/timesheet/list"
        },
        {
          name: "Manquants",
          url: "/dashboard/timesheet/manquants"
        },
        {
          name: "Ignorés",
          url: "/dashboard/timesheet/ignorer"
        },
        {
          name: "Historique des demandes",
          url: "/dashboard/timesheet/prolongations"
        },
        {
          name: "Uploads",
          url: "/dashboard/timesheet/uploads"
        }
      ]
    }
  },
  computed: {
    paths() {
      switch (this.$route.meta.group) {
        case "timesheet":
          return this.timesheetItems
        case "profile":
          return this.profileItems
        case "dossier":
          return this.dossierItems
        case "rapport":
          return this.rapportItems
        case "roles":
          return this.rolesItems
        default:
          return []
      }
    },
    userImage() {
      return this.imageHost + this.$store.state.user.image
    }
  },
  mounted() {
    this.imageHost = process.env.VUE_APP_THUMBNAILS_PATH
  },
  methods: {
    ToggleDrawer() {
      this.$emit("toggle", "test")
    },
    logout() {
      this.$store.dispatch("logout")
    }
  }
}
</script>
<style scoped>
.v-toolbar__content {
  padding-left: 0 !important;
  background: white;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 6px !important;
}

#username {
  font-family: "Lato", sans-serif;
  color: #003d42;
  font-size: 16px;
}

#my-account {
  font-family: "Segoe UI", sans-serif;
  color: #003d42;
  font-size: 20px;
  text-decoration: none;
}
.nav-menu {
  display: inline-block;
  list-style-type: none;
}
.nav-menu-item {
  margin-left: 30px;
  float: left;
  text-decoration: none;
  color: #707070;
}
.v-application ul {
  padding-left: 0;
}
.user-avatar {
  height: inherit !important;
  min-width: 71px !important;
}
</style>
